<template>
  <!--   <ion-page>-->
  <ion-content :scroll-events="true">
    <!--   <ion-grid fixed>
        <ion-row
          class="ion-justify-content-center ion-align-items-center ion-margin-top"
        >
          <ion-col
            class="ion-align-self-center ion-margin-top"
            size-md="6"
            size="12"
          > -->
    <ion-card>
      <ion-card-header>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-card-title size="4">
              {{ editing ? "Editar Campaña Cultivo" : "Crear Campaña Cultivo" }}
            </ion-card-title>
          </ion-col>
          <ion-col size="auto" style="padding-left: 0px; padding-right: 0px;">
            <ion-button
              size="large"
              fill="clear"
              style="margin-left: 0px; margin-right: 0px;"
              @click="setAbierto"
            >
              <ion-icon
                v-if="!abierto"
                color="dark"
                :md="icons.closed"
                :ios="icons.closed"
              ></ion-icon>
              <ion-icon
                v-if="abierto"
                color="primary"
                :md="icons.open"
                :ios="icons.open"
              ></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-left">
          <ion-label class="ion-align-self-center label margen5px">
            {{ "Productor: " }}
          </ion-label>
        </ion-row>
        <ion-row>
          <ion-text
            class="ion-align-self-center ion-margin-top ion-text-capitalize negrita"
          >
            {{ persona.nombre + " " + persona.apellido }}
          </ion-text>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-left">
          <ion-label
            class="ion-align-self-center ion-margin-top label margen5px"
          >
            {{ "Lote: " }}
          </ion-label>
        </ion-row>
        <ion-row>
          <ion-text
            class="ion-align-self-center ion-margin-top ion-text-capitalize negrita"
          >
            {{ lote.nombre + " - " + lote.localidad }}
          </ion-text>
        </ion-row>
      </ion-card-header>
      <ion-card-content>
        <v-form @submit="submit">
          <ion-item>
            <ion-label :position="campaniaActual ? 'stacked' : 'floating'">
              Campaña
            </ion-label>
            <ion-select
              :value="campaniaActual"
              :selected-text="
                campaniaActual && campaniaActual.nombre
                  ? campaniaActual.esActual
                    ? '*' + campaniaActual.nombre.toUpperCase()
                    : campaniaActual.nombre
                  : undefined
              "
              @ionChange="campaniaActual = $event.target.value"
            >
              <ion-select-option
                v-for="camp in campanias"
                :value="camp._id"
                :key="camp._id"
              >
                {{
                  camp.esActual ? "*" + camp.nombre.toUpperCase() : camp.nombre
                }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label :position="cultivo ? 'stacked' : 'floating'">
              Cultivo
            </ion-label>
            <ion-select
              :value="cultivo"
              :selected-text="
                cultivo && cultivo.nombre && cultivo.nombreCientifico
                  ? cultivo.nombre + ' (' + cultivo.nombreCientifico + ')'
                  : undefined
              "
              @ionChange="cultivo = $event.target.value"
            >
              <ion-select-option v-for="c in cultivos" :value="c" :key="c._id">
                {{ c.nombre + " (" + c.nombreCientifico + ")" }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item :disabled="!cultivo">
            <ion-label
              :position="semillero && semillero._id ? 'stacked' : 'floating'"
            >
              Semillero
            </ion-label>
            <ion-select
              :value="semillero"
              :selected-text="
                semillero && semillero.nombre ? semillero.nombre : undefined
              "
              @ionChange="semillero = $event.target.value"
              placeholder="Seleccione semillero"
            >
              <ion-select-option
                v-for="s in semilleros"
                :value="s"
                :key="s._id"
              >
                {{ s.nombre }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item v-if="semillero && semillero.nombre === 'Otro'">
            <ion-label position="floating">
              Descripción de Semillero
            </ion-label>
            <ion-input
              v-model="espSemillero"
              autocapitalize="Words"
              type="text"
              inputmode="text"
              autocomplete="off"
            ></ion-input>
          </ion-item>
          <ion-item :disabled="!cultivo">
            <ion-label :position="variedad ? 'stacked' : 'floating'">
              {{ lblVariedadHibrido }}
            </ion-label>
            <ion-select
              :value="variedad"
              @ionChange="variedad = $event.target.value"
              :selected-text="
                variedad && variedad.variedad ? variedad.variedad : undefined
              "
              :placeholder="'Seleccione ' + lblVariedadHibrido"
            >
              <ion-select-option
                v-for="v in variedades"
                :value="v"
                :key="v._id"
              >
                {{ v.variedad }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item v-if="variedad && variedad.variedad === 'Otra'">
            <ion-label position="floating">
              Descripción de Variedad
            </ion-label>
            <ion-input
              v-model="espVariedad"
              autocapitalize="Words"
              type="text"
              inputmode="text"
              autocomplete="off"
            ></ion-input>
          </ion-item>
          <ion-item
            v-if="
              cultivo &&
                (cultivo.nombre.toLowerCase().includes('maiz') ||
                  cultivo.nombre.toLowerCase().includes('maíz') ||
                  cultivo.nombre.toLowerCase().includes('soja'))
            "
          >
            <ion-label position="floating">
              Biotecnología
            </ion-label>
            <ion-select
              :value="biotecnologia"
              @ionChange="biotecnologia = $event.target.value"
            >
              <ion-select-option
                v-for="b in biotecnologias.filter(
                  b =>
                    cultivo.nombre
                      .toLowerCase()
                      .includes(b.cultivo.toLowerCase()) ||
                    b.cultivo === 'Ambos' ||
                    (cultivo.nombre.toLowerCase().includes('maíz') &&
                      b.cultivo === 'Maiz')
                )"
                :value="b.nombre"
                :key="b.nombre"
              >
                <ion-text>
                  {{ b.nombre }}
                </ion-text>
              </ion-select-option>
            </ion-select>
          </ion-item>
          <div
            class="ion-margin-top"
            v-if="cultivo && cultivo.nombreLower === 'soja'"
          >
            <ion-label style="margin-left: 8px">
              ¿Inoculante?
            </ion-label>
            <ion-radio-group v-model="inoculante">
              <ion-grid fixed>
                <ion-row class="ion-align-items-center">
                  <ion-col size="auto">
                    <ion-label style="margin-right: 8px">Si</ion-label>
                    <ion-radio
                      @click="inoculante = 'Si'"
                      value="Si"
                    ></ion-radio>
                  </ion-col>
                  <ion-col size="auto">
                    <ion-label style="margin-right: 8px">No</ion-label>
                    <ion-radio
                      @click="inoculante = 'No'"
                      value="No"
                    ></ion-radio>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-radio-group>
          </div>

          <ion-item class="ion-margin-top">
            <ion-text style="font-weight:bold">Antecesor</ion-text>
            <ion-label :position="cultivoAntecesor ? 'stacked' : 'floating'">
              Cultivo Antecesor
            </ion-label>
            <ion-select
              :value="cultivoAntecesor"
              @ionChange="cultivoAntecesor = $event.target.value"
              :selected-text="
                cultivoAntecesor &&
                cultivoAntecesor.nombre &&
                cultivoAntecesor.nombreCientifico
                  ? cultivoAntecesor.nombre +
                    ' (' +
                    cultivoAntecesor.nombreCientifico +
                    ')'
                  : undefined
              "
            >
              <ion-select-option
                v-for="c in cultivos"
                :value="c._id"
                :key="c._id"
              >
                {{ c.nombre + " (" + c.nombreCientifico + ")" }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <div v-if="false">
            <ion-item class="ion-margin-top">
              <ion-text style="font-weight:bold">Del Lote</ion-text>
              <ion-label position="floating">
                Tenencia
              </ion-label>
              <ion-select
                :value="tenencia"
                @ionChange="tenencia = $event.target.value"
              >
                <ion-select-option v-for="t in tenencias" :value="t" :key="t">
                  {{ t }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label position="floating" class="ion-margin-top">
                Clase de Suelo
              </ion-label>
              <ion-select
                :value="claseSuelo"
                @ionChange="claseSuelo = $event.target.value"
              >
                <ion-select-option
                  v-for="cS in clasesSuelo"
                  :value="cS"
                  :key="cS"
                >
                  {{ cS }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label position="floating" class="ion-margin-top">
                Calidad del Lote
              </ion-label>
              <ion-select
                :value="calidadLote"
                @ionChange="calidadLote = $event.target.value"
              >
                <ion-select-option
                  v-for="cL in calidadesLote"
                  :value="cL"
                  :key="cL"
                >
                  {{ cL }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <ion-row class="ion-float-right">
            <ion-col>
              <ion-button
                fill="outline"
                class="ion-margin-top"
                color="medium"
                @click="cancel"
              >
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button class="ion-float-right ion-margin-top" type="submit">
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </v-form>
      </ion-card-content>
    </ion-card>
    <!--           </ion-col>
        </ion-row>
      </ion-grid>-->
  </ion-content>
  <!--</ion-page> -->
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import {
  //IonPage,
  IonLabel,
  IonInput,
  IonText,
  IonCardHeader,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonCard,
  IonIcon,
  IonCardTitle,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCardContent,
  //IonGrid,
  alertController,
  IonButton
} from "@ionic/vue";
import { mapState } from "vuex";
import { Campanias, Cultivos, VariedadesCultivo } from "@/api";
import { lockClosed, lockOpen } from "ionicons/icons";
//import isEmail from "validator/lib/isEmail";

export default {
  components: {
    //IonPage,
    IonText,
    IonInput,
    IonCardHeader,
    IonLabel,
    IonGrid,
    IonRadioGroup,
    IonRadio,
    IonIcon,
    IonItem,
    IonCard,
    IonCardContent,
    IonSelect,
    IonSelectOption,
    IonCardTitle,
    IonContent,
    IonRow,
    //IonGrid,
    IonButton,
    VForm: V.Form
    //VField: V.Field,
    //VErrorMessage: V.ErrorMessage
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    lote: { type: Object, default: () => ({}) },
    persona: { type: Object, default: () => ({}) },
    campaniaCultivo: { type: Object, default: () => ({}) }
  },

  data: () => ({
    reset: false,
    campaniaActual: null,
    cultivo: null,
    cultivos: [],
    variedad: null,
    variedades: [],
    cultivoAntecesor: null,
    semillero: null,
    semilleros: [],
    abierto: true,
    tenencia: "",
    claseSuelo: "",
    calidadLote: "",
    tenencias: ["Propio", "Arrendado", "Asesorado"],
    clasesSuelo: ["I", "II", "III", "IV", "V", "VI", "Sin Dato"],
    calidadesLote: ["Alto Potencial", "Medio Potencial", "Bajo Potencial"],
    campanias: [],
    inoculante: "",
    biotecnologia: "",
    espSemillero: "",
    espVariedad: "",
    biotecnologias: [
      { nombre: "RR", cultivo: "Ambos" },
      { nombre: "Leptra", cultivo: "Maiz" },
      { nombre: "Powercore", cultivo: "Maiz" },
      { nombre: "Powercore Enlist", cultivo: "Maiz" },
      { nombre: "Powercore Ultra", cultivo: "Maiz" },
      { nombre: "Viptera", cultivo: "Maiz" },
      { nombre: "NO Bt", cultivo: "Maiz" },
      { nombre: "VT3Pro", cultivo: "Maiz" },
      { nombre: "VT4Pro", cultivo: "Maiz" },
      { nombre: "HB4", cultivo: "Soja" },
      { nombre: "STS", cultivo: "Soja" },
      { nombre: "STS-Enlist", cultivo: "Soja" },
      { nombre: "No GMO", cultivo: "Ambos" },
      { nombre: "Otro", cultivo: "Ambos" }
    ],
    icons: {
      open: lockOpen,
      closed: lockClosed
    }
    //error: "",
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return this.campaniaCultivo._id;
    },
    lblVariedadHibrido() {
      return this.cultivo &&
        this.cultivo.nombre &&
        (this.cultivo.nombreLower.includes("maiz") ||
          this.cultivo.nombreLower.includes("maíz") ||
          this.cultivo.nombreLower.includes("girasol"))
        ? "Híbrido"
        : "Variedad";
    }
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    campaniaCultivo() {
      this.cleaner();
      if (this.editing) {
        this.fillFields();
      }
    },
    async cultivo(val) {
      this.variedades = await this.getVariedades();
      this.semilleros = await this.getSemilleros();
      if (this.semillero && this.semillero._id) {
        if (this.semilleros.map(s => s._id).indexOf(this.semillero._id) == -1) {
          this.semillero = null;
        }
      }
      this.biotecnologia = "";
      if (val.nombreLower !== "soja") {
        this.inoculante = "";
      }
      this.espSemillero = "";
      this.espVariedad = "";
    },

    async semillero() {
      this.variedades = await this.getVariedades();
      if (
        this.variedad &&
        this.variedad._id &&
        this.variedades.map(v => v._id).indexOf(this.variedad._id) == -1
      ) {
        this.variedad = null;
      }
    },

    async variedad(val) {
      this.espVariedad = "";
      if (val && val.nombre !== "Otra") {
        this.semillero = val.semillero
          ? val.semillero
          : { _id: "0", nombre: "Otro" };
      } else if (!this.semillero) {
        this.semillero = { _id: "0", nombre: "Otro" };
      }
    }
  },

  async mounted() {
    await this.getCampanias();
    this.cultivos = await this.getCultivos();
    if (this.editing) {
      this.fillFields();
    } else {
      this.campaniaActual = this.campanias.find(c => c.esActual === true);
    }
  },

  created() {
    if (this.editing) {
      this.fillFields();
    }
  },

  methods: {
    cleaner() {
      this.reset = !this.reset;
      this.cultivo = {};
      this.abierto = true;
      this.semillero = null;
      this.variedad = null;
      this.cultivoAntecesor = null;
      this.campaniaActual = null;
      this.campanias = [];
      this.tenencia = "";
      this.claseSuelo = "";
      this.calidadLote = "";
      this.inoculante = "";
      this.biotecnologia = "";
      this.espSemillero = "";
      this.espVariedad = "";
      //this.error = "";
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    async setAbierto() {
      if (this.abierto === true) {
        const alert = await alertController.create({
          header: "Campaña Cultivo",
          message: "Desea cerrar esta Campaña Cultivo?",
          buttons: [
            {
              text: "Si",
              handler: async () => {
                this.abierto = false;
              }
            },
            {
              text: "No"
            }
          ]
        });
        return alert.present();
      }
      const alert = await alertController.create({
        header: "Campaña Cultivo",
        message: "Desea abrir esta Campaña Cultivo?",
        buttons: [
          {
            text: "Si",
            handler: async () => {
              this.abierto = true;
            }
          },
          {
            text: "No"
          }
        ]
      });
      return alert.present();
    },

    async getCampanias() {
      //antes getCampanias
      const { data, error } = await Campanias.get({
        query: {
          estado: "HABILITADO"
        },
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo campañas",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      } else {
        this.campanias = data;
      }
    },

    async getCultivos() {
      const { data, error } = await Cultivos.get({
        sort: { nombreLower: 1, nombreCientificoLower: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Cultivos",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      } else {
        let cultivos = data;
        return cultivos;
      }
    },

    async getVariedades() {
      const query = {
        cultivo: this.cultivo
      };
      if (this.semillero && this.semillero.nombre !== "Otro") {
        query.semillero = this.semillero;
      }
      const { data, error } = await VariedadesCultivo.get({
        query: query,
        sort: { variedadLower: 1 },
        populate: "semillero"
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Variedades del Cultivo",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      } else {
        let variedades = data;
        variedades = variedades.filter(c => c.variedad !== "Otra");
        variedades.push({ _id: "0", variedad: "Otra" });
        return variedades;
      }
    },

    removeDuplicatesFromArray(arr) {
      const unique = arr.filter((obj, index, self) => {
        return self.findIndex(s => s._id === obj._id) === index;
      });
      return unique;
    },

    async getSemilleros() {
      const { data, error } = await VariedadesCultivo.get({
        query: { cultivo: this.cultivo },
        sort: { nombreLower: 1 },
        populate: "semillero"
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Semilleros",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      } else {
        const semilleros = data
          .filter(d => !!d.semillero && !!d.semillero)
          .map(d => d.semillero);

        const semillerosSorted = this.removeDuplicatesFromArray(
          semilleros.sort((a, b) => {
            return a.nombreLower.localeCompare(b.nombreLower);
          })
        );

        let semillerosFiltered = semillerosSorted.filter(
          s => s.nombre !== "Otro"
        );
        semillerosFiltered.push({ _id: "0", nombre: "Otro" });
        return semillerosFiltered;
      }
    },

    fillFields() {
      this.campaniaActual = this.campaniaCultivo.campania;
      this.abierto =
        this.campaniaCultivo &&
        (this.campaniaCultivo.abierto === true ||
          this.campaniaCultivo.abierto === false)
          ? this.campaniaCultivo.abierto
          : true;
      this.cultivo = this.campaniaCultivo.cultivo;
      this.semillero = this.campaniaCultivo.semillero
        ? this.campaniaCultivo.semillero
        : { _id: "0", nombre: "Otro" };
      this.variedad = this.campaniaCultivo.variedadCultivo
        ? this.campaniaCultivo.variedadCultivo
        : { _id: "0", variedad: "Otra" };
      this.cultivoAntecesor = this.campaniaCultivo.cultivoAntecesor;
      this.biotecnologia =
        this.cultivo.nombreLower === "soja" ||
        this.cultivo.nombreLower === "maiz"
          ? this.campaniaCultivo.biotecnologia
          : "";
      this.inoculante =
        this.cultivo.nombreLower === "soja"
          ? this.campaniaCultivo.inoculante
          : "";
      setTimeout(() => {
        this.espSemillero =
          this.campaniaCultivo.especificacionSemillero !== undefined
            ? this.campaniaCultivo.especificacionSemillero
            : "";
        this.espVariedad =
          this.campaniaCultivo.especificacionVariedad !== undefined
            ? this.campaniaCultivo.especificacionVariedad
            : "";
      }, 200);
    },

    async submit() {
      this.$emit("update:success", false);
      let error = "";
      if (this.campaniaActual === null) {
        error += "<li>Elija una <b>Campaña</b>." + `<br/></li>`;
      }
      if (this.cultivo === null) {
        error += "<li>Elija un <b>Cultivo</b>." + `<br/></li>`;
      }
      if (this.variedad === null) {
        error += "<li>Elija una <b>Variedad del Cultivo</b>." + `<br/></li>`;
      }
      if (this.semillero === null) {
        error += "<li>Elija un <b>Semillero</b>." + `<br/></li>`;
      }
      if (this.cultivoAntecesor === null) {
        error += "<li>Elija un <b>Cultivo Antecesor</b>." + `<br/></li>`;
      }
      if (
        this.cultivo &&
        this.cultivo.nombreLower === "soja" &&
        this.inoculante === ""
      ) {
        error +=
          "<li>Elija una opción para el campo <b>¿Inoculante?</b>." +
          `<br/></li>`;
      }
      if (
        this.cultivo &&
        (this.cultivo.nombreLower === "soja" ||
          this.cultivo.nombreLower === "maiz") &&
        this.biotecnologia === ""
      ) {
        error +=
          "<li>Elija una opción para el campo <b>Biotecnología</b>." +
          `<br/></li>`;
      }
      if (this.semillero.nombre === "Otro" && this.espSemillero === "") {
        error +=
          "<li>Complete el el campo <b>Descripción de Semillero</b>." +
          `<br/></li>`;
      }
      if (this.variedad.variedad === "Otra" && this.espVariedad === "") {
        error +=
          "<li>Complete el el campo <b>Descripción de Variedad</b>." +
          `<br/></li>`;
      }
      if (!error) {
        this.$emit("submit", {
          campaniaCultivo: {
            _id: this.campaniaCultivo._id || undefined,
            campania: this.campaniaActual,
            abierto: this.abierto,
            cultivo: this.cultivo,
            variedadCultivo:
              this.variedad.variedad === "Otra" ? null : this.variedad,
            cultivoAntecesor: this.cultivoAntecesor,
            semillero: this.semillero.nombre === "Otro" ? null : this.semillero,
            lote: this.lote,
            biotecnologia: this.biotecnologia,
            inoculante:
              this.cultivo.nombreLower === "soja" && this.inoculante !== ""
                ? this.inoculante
                : undefined,
            especificacionSemillero:
              this.semillero.nombre === "Otro" ? this.espSemillero : undefined,
            especificacionVariedad:
              this.variedad.variedad === "Otra" ? this.espVariedad : undefined
          },
          lote: {
            _id: this.lote._id,
            tenencia: this.tenencia ? this.tenencia : undefined,
            claseSuelo: this.claseSuelo ? this.claseSuelo : undefined,
            calidadLote: this.calidadLote ? this.calidadLote : undefined
          }
        });
      } else {
        const alert = await alertController.create({
          header: "Error al crear la campaña - cultivo",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}
</style>
